import Section from "../components/main/site/main/Section"
import * as React from "react";
import { Typography } from "@mui/material"
import Layout from "../components/main/layout/Layout";
const Impressum = () => {
    return(
        <Layout>
        <Section sx={{minHeight:"60vh"}}>
        <Typography variant="h3" component="h1">Impressum</Typography>
        <Typography variant="body1" sx={{mt:2}}>
            Geschäftsführer:<br/>
            Dennis Schuchardt<br/>
            Adresse:<br/>
            Hauptstr.126<br/>
            31171 Nordstemmen<br/>
            Telefonnummer: 0176/91322033<br/>
            Steuernummer: 30/142/06778<br/>
            Finanzamt: Hildesheim-Alfeld<br/>
         </Typography>
        </Section>
        </Layout>

    )
}
export default Impressum
